.gt-questionPanel {
  &__header {
    display: flex;
    flex-direction: row;

    &-item {
      flex: 1;
      font-size: map-get($fontSizes, 12);
      padding: map-get($sizes, 2) map-get($sizes, 4);
      font-weight: 600;
    }

    &-actions {
      flex: 0 0 80px;
      padding: map-get($sizes, 2) map-get($sizes, 3);
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      &-iconUnselected {
        color: $unfilledAnswerStateColor;
        cursor: pointer;
      }

      &-iconSelected {
        color: $filledAnswerStateColor;
        cursor: pointer;
      }

      &-iconUnselectable {
        color: $isNotAnsweredStateColor;
        cursor: pointer;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;

    &-leftSidePlaceholder {
      flex: 2;
      padding: map-get($sizes, 2);
    }

    &-rightSidePlaceholder {
      display: flex;
      justify-content: end;
      flex: 1;
      padding: map-get($sizes, 1);
    }

    &-actions {
      flex: 0 0 80px;
      padding: map-get($sizes, 2) map-get($sizes, 3);
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      &-iconUnselected {
        color: $unfilledAnswerStateColor;
        cursor: pointer;
      }

      &-iconSelected {
        color: $filledAnswerStateColor;
        cursor: pointer;
      }

      &-iconUnselectable {
        color: $isNotAnsweredStateColor;
        cursor: pointer;
      }
    }
  }

  &__rows {
    border: 1px solid $accordionRowsBorderColor;
  }
}
