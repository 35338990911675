.gt-banner {
  $blockName: &;
  display: flex;
  flex-direction: row;
  border-radius: map-get($sizes, 3);
  padding: map-get($sizes, 2) map-get($sizes, 3);
  gap: map-get($sizes, 2);
  margin: map-get($sizes, 2) 0;

  &__main {
    display: flex;
    flex-direction: column;
    flex: auto;
    &-title {
      font-size: map-get($fontSizes, 14);
      font-weight: 600;
    }

    &-message {
      font-size: map-get($fontSizes, 14);
    }

    &-link {
      font-size: map-get($fontSizes, 14);
      font-weight: 600;
      margin-top: map-get($sizes, 2);
      a {
        text-decoration: none;
      }
    }
  }

  &__closeBtn {
    .MuiSvgIcon-root {
        cursor: pointer;
    }
  }

  &--infoState {
    border: 1px solid $infoBannerMainColor;
    background-color: rgba($infoBannerMainColor, 0.1);
    #{$blockName} {
      &__icon {
        color: $infoBannerMainColor;
      }

      &__main {
        &-link {
          a, a:visited {
            color: $infoBannerMainColor;
          }
        }
      }

      &__closeBtn {
        color: $infoBannerMainColor;
      }
    }
  }

  &--alertState {
    border: 1px solid $alertBannerMainColor;
    background-color: rgba($alertBannerMainColor, 0.1);
    #{$blockName} {
      &__icon {
        color: $alertBannerMainColor;
      }

      &__main {
        &-link {
            a, a:visited {
                color: $alertBannerMainColor;
              }
        }
      }

      &__closeBtn {
        color: $alertBannerMainColor;
      }
    }
  }
}
