.gt-engagementLetterDocumentsPanel {
  display: flex;
  flex-direction: column;
  margin-top: map-get($sizes, 5);
  &__accordion {
    &-summary {
      &-titleHref {
        text-decoration: none;
      }
    }
  }
}

.gt-documentsManager {
  $blockName: &;

  display: flex;
  &__left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $midGrey80;

    &-uploadContainer {
      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-link {
          color: rgb(160, 109, 255);
        }
      }
      &.MuiButtonBase-root.MuiButton-root {
        color: $darkPurple;
        border: 2px solid $midGrey80;
        background-color: $lightGrey20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: map-get($sizes, 2);
        margin-right: map-get($sizes, 5);
        gap: map-get($sizes, 4);
        box-shadow: none;
        width: 100%;
        max-width: 750px;
      }
      &--disabled {
        &.MuiButtonBase-root.MuiButton-root {
          border: none;

          span, a {
            color: $midGrey80;
          }
        }
      }
    }
  }
  &__right {
    flex: 0 0 386px;
    padding-left: map-get($sizes, 6);
  }
}
