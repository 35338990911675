.gt-accordion {
  $blockName: &;
  box-shadow: none;
  border: 1px solid $midGrey80;

  &__header {
    display: flex;
    gap: map-get($sizes, 4);
    padding: map-get($sizes, 2) map-get($sizes, 3);
    background-color: $tealColor;
    border: 1px solid $accordionHeaderBorderColor;
    color: $whiteColor;

    &-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &-title {
        font-size: map-get($fontSizes, 16);
        font-weight: 600;
        line-height: map-get($fontSizes, 20);
      }

      &-expandedText {
        display: flex;
        font-size: map-get($fontSizes, 14);
        font-weight: 600;
        line-height: map-get($fontSizes, 20);
        justify-content: space-between;
        align-items: center;
        gap: map-get($sizes, 1);

        &-icon {
          display: flex;
          gap: 4px;

          &-isAllFilledCheck {
            color: $greenColor;
          }
        }
      }

      &-expandedTextWithoutIcon {
        font-size: map-get($fontSizes, 14);
        font-weight: 600;
        line-height: map-get($fontSizes, 20);
      }
    }

    &.MuiAccordionSummary-root {
      min-height: 40px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  &__content {
    padding: 0 map-get($sizes, 1);
  }

  &--notAllQuestionsAnsweredState {
    #{$blockName} {
      &__header {
        background-color: $accordionHeadeBgColor;
        color: $darkPurple;
      }
    }
  }

  &--allQuestionsAnsweredState {
    #{$blockName} {
      &__header {
        background-color: $accordionHeadeDoneBgColor;
      }
    }
  }

  &--allQuestionsAnsweredIsDefaultState {
    #{$blockName} {
      &__header {
        &-container {
          &-expandedText {
            &-icon {
              color: $blueColor;
            }
          }
        }
      }
    }
  }

  &--notAllQuestionsAnsweredIsDefaultState {
    #{$blockName} {
      &__header {
        &-container {
          &-expandedText {
            &-icon {
              color: $yellowColor;
            }
          }
        }
      }
    }
  }
}