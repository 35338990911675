.gt-leftSidebarContainer {
  $blockName: &;
  width: 260px;
  border-right: 1px solid $leftSidebarBorderColor;
  background-color: $leftSidebarBgColor;
  height: calc(100vh - $headerHeight);
  padding: map-get($sizes, 4) map-get($sizes, 3);
  position: sticky;
  top: $headerHeight;
  z-index: $leftSidebarZIndex;
  transition: all 200ms;

  &-toggleVisibilityIcon {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    top: 20px;
    right: -16px;
    background: $whiteColor;
    border: 2px solid $leftSidebarBorderColor;
    border-radius: 50%;
    transition: all 500ms;
  }

  &__generalInfo {
    margin-bottom: map-get($sizes, 4);
    padding-bottom: map-get($sizes, 2);
    padding-right: map-get($sizes, 1);
    border-bottom: 1px solid $leftSidebarBorderColor;
    &-title {
      font-size: map-get($fontSizes, 18);
      line-height: map-get($fontSizes, 28);
      font-weight: 600;
      margin-bottom: map-get($sizes, 2);
    }

    &-row {
      font-size: map-get($fontSizes, 14);
      padding: map-get($sizes, 1) 0;
      &-label {
        margin-right: map-get($sizes, 1);
      }
      &-value {
        font-weight: 600;
      }
    }
  }

  &__wrapper {
    &-subtitle1 {
      font-size: map-get($fontSizes, 16);
      font-weight: bold;
    }

    &-serviceLinks {
      height: 100%;
      max-height: calc(100vh - 395px);
      overflow: hidden;
      padding-bottom: map-get($sizes, 5);

      &-selectedService {
        font-size: map-get($fontSizes, 14);
        padding-top: map-get($sizes, 2);
        padding-bottom: map-get($sizes, 2);
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 1px solid $leftSidebarBorderColor;
        }
      }

      &:hover {
        overflow: auto;
        margin-right: -$scrollbarWidth;
      }
    }
  }

  &--hiddenState {
    margin-left: -240px;
    #{$blockName} {
      &-toggleVisibilityIcon {
        transform: rotate(180deg);
      }
    }
  }
}
