.gt-dialog {
  @include gtScroll();

  &__actionsContainer {
      padding: map-get($sizes, 2) map-get($sizes, 5) map-get($sizes, 5);
      justify-content: space-between;
      &-left,
      &-right {
        display: flex;
        gap: map-get($sizes, 2);
      }
  }
}