.gt-mainHeader {
  position: sticky;
  top: 0;
  z-index: $headerZIndex;
  min-width: 1024px;
  
  &__topUtilities {
    display: flex;
    align-items: center;
    padding: 0 map-get($sizes, 3);
    justify-content: space-between;
    height: $headerTopUtilitiesHeight;
    background-color: $headerTopUtilitiesBgColor;
    
    &-actionSide {
      display: flex;
      align-items: center;
      gap: map-get($sizes, 3);

      &-languageSelector {
        color: $headerTopUtilitiesTextColor;
        fieldset {
          border: none;
        }
  
        svg {
          color: $headerTopUtilitiesTextColor;
        }
      }
    }
  }

  &__primaryRow {
    padding: map-get($sizes, 3);
    background-color: $headerBgColor;
    display: flex;
    justify-content: space-between;

    &-info {
      &-title {
        color: $headerTextColor;
        font-size: map-get($fontSizes, 20);
        font-weight: 600;
      }

      &-data {
        display: flex;
        font-size: map-get($fontSizes, 14);
        line-height: map-get($fontSizes, 20);
        font-weight: 400;   
        margin: map-get($sizes, 2) 0;  
        &-label {
          color: $headerInfoLabelColor;     
          margin-right: map-get($sizes, 2);   
        }

        &-value {
          display: flex;
          gap: map-get($sizes, 2);  
          color: $headerInfoValueColor;  
          margin-right: map-get($sizes, 8);      
        }
      }
    }

    &-ctas {
      button:not(:last-child) {
        margin-right: map-get($sizes, 2);
      }
    }
  }

  &__breadcrumbsContainer {
    padding: map-get($sizes, 3);
    background-color: $headerBgColor;
    color: $whiteColor;
    border-top: 1px solid rgba($whiteColor, 0.15);

    &-breadcrumbs {
      &-item {
        color: $whiteColor;
        text-decoration: underline;
        text-transform: none;
        padding: map-get($sizes, 1) map-get($sizes, 2);
        border-radius: map-get($sizes, 1);
        font-size: map-get($fontSizes, 14);
        font-weight: 600;

        &--active {
          color: $headerBgColor;
          background-color: $whiteColor;
          text-decoration: none;
        }
      }
    }

    &-nextIcon {
      color: $whiteColor;
    }
  }
}


.gt-descriptionTooltip {
  display: flex;
  flex-direction: column;
  background: $whiteColor;
  margin: -1*map-get($sizes, 1) -1*map-get($sizes, 2);
  padding: map-get($sizes, 2);
  color: $blackColor;
  border-radius: 3px;
  border: 1px solid $midGrey80;

  &__title {
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 22);
    font-weight: 600;
    margin-bottom: map-get($sizes, 1);
  }

  &__description {
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 20);
    font-weight: 400;
    margin-bottom: map-get($sizes, 2);;
  }
}

.gt-userMenu {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $whiteColor;
  color: $darkPurple80;
  height: $userMenuSelectorSize;
  width: $userMenuSelectorSize;
  border-radius: 50%;
  overflow: hidden;
}

.gt-userMenuBar {
  &__info {
    display: flex;
    gap: map-get($sizes, 2);
    padding: map-get($sizes, 1) map-get($sizes, 2);
    border-bottom: 1px solid $midGrey;

    &-initials {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $midGrey40;
      border: 2px solid $midGrey;
      color: $darkPurple80;
      height: $userMenuSelectorSize;
      width: $userMenuSelectorSize;
      border-radius: 50%;
      overflow: hidden;
    }

    &-data {
      &-name {
        font-size: map-get($fontSizes, 14);
        font-weight: 600;
      }
      &-email {
        font-size: map-get($fontSizes, 14);
        font-weight: 400;
        color: $greyColor;
      }
    }
  }

  &__menuItem {
    display: flex;
    gap: map-get($sizes, 1);
    color: $darkPurple60;
  }

  &-__separator {
    border-bottom: 1px solid $midGrey;
  }
}