.gt-tasksPanel {
  @include data-table(
    $dataTableHeaderBgColor,
    $dataTableHeaderTextColor,
    $whiteColor,
    $dataTableBorderCellColor
  );

  &__dataContainer {
    display: flex;
  }

  &__highlightedCell {
    background-color: $lightGrey20;
    font-weight: bold;
  }

  &__tableHeaderCell {
    background-color: $dataTableHeaderBgColor;
    color: $dataTableHeaderTextColor;
    font-size: map-get($fontSizes, 12);
    line-height: map-get($fontSizes, 18);
    font-weight: 500;

    article {
      @include line-clamp(3);
      white-space: break-spaces;
      overflow-wrap: break-word;
      font-size: map-get($fontSizes, 14);
      line-height: 1.1;
      text-align: left;
      font-weight: 600;
    }

    &.MuiDataGrid-cell {
      justify-content: flex-start;
      padding: 3px 10px;
      background-color: $dataTableHeaderBgColor;
      color: $dataTableHeaderTextColor;
      font-size: map-get($fontSizes, 12);
      line-height: map-get($fontSizes, 18) !important;
      font-weight: 500;
    }
  }

  &--isClientTask {
    .MuiDataGrid-cell {
      background-color: $midGrey20;
    }
  }

  &--isVisibleOnlyInCalculation {
    .MuiDataGrid-cell.gt-dataTableTaskTitle {
      background-color: $lightYellowColor;
    }
  }

  &--disabledCellState {
    .MuiDataGrid-cell {
      background-color: $redTooth;
      color: $midGrey;
    }
  } 
  
  &--isAutomaticTask {
    .MuiDataGrid-rowReorderCell--draggable {
      display: none;
    }
  }

  &--recurringServices {
    max-width: 750px;
  }
  .MuiDataGrid-cell.gt-noBorderCell {
    border: none;
  }
}

.gt-serviceStaffingAllocationSummaryPanel {
  @include data-table(
    $dataTableHeaderBgColor,
    $dataTableHeaderTextColor,
    $whiteColor,
    $dataTableBorderCellColor
  );
  max-width: 960px;
  margin: 15px 0;

  &__dataContainer {
    display: flex;
  }

  &__highlightedCell {
    background-color: $midGrey40;
    font-weight: bold;

    input {
      font-weight: bold;
    }
  }

  &--widthSmaller {
    max-width: 650px;
  }

  &--extendedVariant {
    max-width: 800px;
  }
}

.gt-additionalCostsPanel {
  @include data-table(
    $dataTableHeaderBgColor,
    $dataTableHeaderTextColor,
    $whiteColor,
    $dataTableBorderCellColor
  );
  max-width: 750px;
  margin: 15px 0;

  &__dataContainer {
    display: flex;
  }

  &__highlightedCell {
    background-color: $midGrey40;
    font-weight: bold;
  }
}

.gt-tasksPanelTasksGroup {
  @include data-table(
    $dataTableHeaderBgColor,
    $dataTableHeaderTextColor,
    $whiteColor,
    $dataTableBorderCellColor
  );

  display: flex;
  flex-direction: column;

  &__dataContainer {
    display: flex;
  }

  &__header {
    padding: map-get($sizes, 2) map-get($sizes, 3);
    background-color: $midGrey80;
    border: 1px solid $midGrey80;
  }
}
