.MuiButton-root {
  text-transform: none;
}

.MuiButton-outlinedTertiary {
  border-color: $midGrey80;
  &:hover {
    border-color: $midGrey80;
  }
}
