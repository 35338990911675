.gt-summaryPanel {
  display: flex;
    &__contactPerson,
    &__data {
      height: 40px;
      font-size: map-get($fontSizes, 14);
      font-weight: 600;
      color: $blackColor;
      display: flex;
      align-items: center;
      padding: 0 map-get($sizes, 8);
      border-right: 2px solid $dataTableBorderCellColor;
    }

    &__contactPerson {
      width: 510px;
    }
    &__data {
      width: 570px;
    }
    &__none{
      height: 40px;
      border-right: 0px solid $dataTableBorderCellColor;
    }
}
