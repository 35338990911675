.gt-manageVersions {

    &__subTitle {     
        font-size: map-get($fontSizes, 16);
        line-height: map-get($fontSizes, 20);
        font-weight: 600;  
        color: $blackColor;  
        margin-bottom: map-get($sizes, 2);
    }
}

.gt-versionItem {        
    $blockName: &;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 72px;
    border: 1px solid $greyColor;
    border-radius: 4px;
    margin: map-get($sizes, 2) 0;
    padding: map-get($sizes, 3);

    &__mainInfo {
        display: flex;
        flex-direction: row;        
        justify-content: space-between;
        margin-right: map-get($sizes, 10);
        &-leftSide {
            &-title {
                font-size: map-get($fontSizes, 16);
                line-height: map-get($fontSizes, 20);
                font-weight: 600;  
                color: $blackColor;      
                margin-right: map-get($sizes, 1);        
            }
            &-createdBy {
                font-size: map-get($fontSizes, 14);
                line-height: map-get($fontSizes, 20);
                font-weight: 400;  
                color: $greyColor;                
            }
        }

        &-rightSide {
            display: flex;
            line-height: map-get($fontSizes, 18);
            gap: map-get($sizes, 1);
            margin-top: map-get($sizes, 1);
        }
    }

    &__comment {            
        font-size: map-get($fontSizes, 14);
        line-height: map-get($fontSizes, 20);
        font-weight: 400;  
        color: $darkGreyColor;  
        margin-top: map-get($sizes, 1);   
    }

    &__icon {
        position: absolute;
        top: 12px;
        right: 12px;
        &-circle {
            color: $greyColor;
        }

        &-checked {
            color: $greenColor;
        }
    }

    &--isSelectedState {
        border: 1px solid $greenColor;
        background-color: rgba($greenColor, 0.08);
    }

    &--isDisabledState {
        opacity: 0.3;
        cursor: not-allowed;
    }
}