.gt-logoutPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__icon {
        font-size: map-get($fontSizes, 60);
        color: $darkPurple80;
        margin-bottom: map-get($sizes, 6);
    }

    &__message {
        font-size: map-get($fontSizes, 24);
        font-weight: 600;
    }

}