.gt-arrowBack {
    display: inline-flex;
    cursor: pointer;
    gap: map-get($sizes, 2);
    margin-bottom: map-get($sizes, 2);
    &__icon {
        background-repeat: no-repeat;
        background-size: $arrowBackIconSize;
        height: $arrowBackIconSize;
        width: $arrowBackIconSize;
    }

    &__label {
      display: flex;
      align-items: center;
      gap: map-get($sizes, 2);
      font-size: map-get($fontSizes, 14);
      line-height: map-get($fontSizes, 20);
    }
}
