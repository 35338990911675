.gt-expandMoreIcon {
    background-repeat: no-repeat;
    background-size: $expandIconSize;
    height: $expandIconSize;
    width: $expandIconSize;

    &--rotateState {
        transform: rotate(180deg);
    }
}
