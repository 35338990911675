.gt-officeManagersDialog {
    &__addButton {
        margin-bottom: map-get($sizes, 3);
    }

    &__row {
        display: flex;
        flex: auto;
        align-items: center;

        .MuiSvgIcon-root {
            cursor: pointer;
            color: $darkPurple80;
            margin-left: map-get($sizes, 1);
        }
    }
}

.gt-officeManagersRow {
    display: flex;
    flex: auto;
    margin: map-get($sizes, 2) 0;

    &__ownerSection,
    &__areaSection {
        display: flex;
        flex-direction: column;
    }

    &__ownerSection {
        flex: 3 0 30%;
        padding-right: map-get($sizes, 3);;
        .MuiSelect-select {
            padding: map-get($sizes, 2) map-get($sizes, 3);
        }
    }

    &__areaSection {
        flex: 3 0 30%;
        padding-right: map-get($sizes, 3);;
        .MuiSelect-select {
            padding: map-get($sizes, 2) map-get($sizes, 3);
        }
    }

    &__businessUnitSection {
        flex: 4 0 40%;
        .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
            padding: 0;
        }

        .MuiAutocomplete-tag {
            margin: map-get($sizes, 1);
        }
    }
}

.gt-changeAssignmentDialog {
    display: flex;
    flex: auto;
    margin: map-get($sizes, 2) 0;

    &__currentAssignmentSection,
    &__newAssignmentSection {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: map-get($sizes, 1);
    }

    &__currentAssignmentSection {
        padding-right: map-get($sizes, 3);
    }
}