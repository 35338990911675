.gt-serviceRow {
  $blockName: &;
  display: flex;
  justify-content: space-between;
  border: 1px solid $colorPlatinium;
  border-radius: 4px;
  padding: map-get($sizes, 2) map-get($sizes, 3);
  cursor: pointer;

  &__title {
    font-weight: 600;
    font-size: map-get($fontSizes, 16);
    line-height: map-get($fontSizes, 22);
  }

  &__icon {
    &-unselected {
      display: flex;
      color: $colorPlatinium;
    }

    &-selected {
      display: none;
      color: $tealColor;
    }
  }

  &--selectedState {
    background-color: rgba($tealColor, 0.1);
    border-color: $tealColor;

    #{$blockName} {
      &__icon {
        &-unselected {
          display: none;
        }

        &-selected {
          display: flex;
        }
      }
    }
  }

  &--disabledState {
    color: $midGrey60;
  }
}