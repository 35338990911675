.gt-taskMenu {

  &__menuButton {
    border-radius: 0;
    width: 20px;  
  }

  .MuiSvgIcon-root {
    color: $blackColor;
  }
}