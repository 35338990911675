.gt-engagementDescriptionGeneralPanel {
  display: flex;
  justify-content: space-between;
  border: 1px solid $midGrey80;
  background-color: $midGrey20;
  padding: map-get($sizes, 5) map-get($sizes, 6);
  margin-bottom: map-get($sizes, 6);

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 map-get($sizes, 3);

    &-label {
      font-size: map-get($fontSizes, 14);
      line-height: map-get($fontSizes, 20);
      font-weight: 400;
      color: $darkPurple60;
    }

    &-value {
      font-size: map-get($fontSizes, 16);
      line-height: map-get($fontSizes, 24);
      color: $brightPurple;
      padding-bottom: map-get($sizes, 2);
    }

    &-dateValue {
      display: flex;
      align-items: center;
    }

    &-divider {
      margin: 0 map-get($sizes, 1);
    }

    &-datePicker.MuiTextField-root {
      min-width: 170px;
      height: 40px;

      .MuiInputBase-formControl {
        width: 170px;

        input {
          padding: map-get($sizes, 2);
          background-color: $whiteColor;
        }
      }
    }
  }
}