.gt-errorPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
    height: 100vh;

    &__administrationMessages {
        width: 100%;
        max-width: 1024px;
    }
    &__logo {
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .gt-spinnerIcon {
            width: 100px;
            height: 100px;
            background-size: cover;
        }
    }
}