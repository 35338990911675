.gt-engagementLettersSummaryViewPage {
  width: 100%;
}

.gt-EngagementLettersFilterPanel {
  margin: map-get($sizes, 4) 0;

  &__row {
    display: flex;
    gap: map-get($sizes, 2);

    &-inputFilterWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      gap: map-get($sizes, 1);

      .MuiInputBase-root {
        height: map-get($sizes, 9);
      }

      &-validityStatus {        
        &--valid,
        &--valid.Mui-selected,
        &--valid:hover  {
          background-color: $validColor;
        }

        &--toexpire,
        &--toexpire.Mui-selected,
        &--toexpire:hover  {
          background-color: $toExpireColor;
        }

        &--expired,
        &--expired.Mui-selected,
        &--expired:hover  {
          background-color: $expiredColor;
        }
      }
    }

    &-checkBoxFilterWrapper {
      display: flex;
      flex: 1;
      align-items: flex-end;
      &--isAdminOrManager {        
        flex: 2;
      }
    }

    &-buttonFilterWrapper {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-end;
      align-items: end;
      gap: map-get($sizes, 2);

      .MuiButton-root {
        flex: 1;
      }
    }
  }
}

.gt-engagementLettersSummaryViewPanel {
  @include data-table(
    $lettersSummaryDataTableHeaderBgColor,
    $lettersSummaryDataTableHeaderTextColor,
    $whiteColor,
    $lettersSummaryDataTableBorderCellColor
  );

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 400;
    line-height: map-get($fontSizes, 18);
  }

.MuiDataGrid-columnHeader:focus {
    outline: none;
}

  .MuiDataGrid-footerContainer {
    background-color: $lettersSummaryDataTableFooterBgColor;
    display: flex;
    min-height: 44px;
    height: 44px;
  }
}

.gt-lettersSummaryActionCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;

  &__linkIcon {
    display: flex;
    color: $brightPurple;
  }

  &__userIcon {
    cursor: pointer;
  }
}

.gt-lettersSummaryCell {  
  &.MuiDataGrid-cell {
    padding: 0 map-get($sizes, 1);
  }

  &--valid {
    background-color: $validColor;
  }

  &--toExpire {
    background-color: $toExpireColor;
  }

  &--expired {
    background-color: $expiredColor;
  }

  &--deactivated {
    opacity: 0.4;
  }
}