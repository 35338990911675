.gt-documentsList {
  $blockName: &;
  list-style-type: none;
  padding-left: 0;
  margin: 0px;

  &__li {
    display: flex;
    justify-content: space-between;

    &-wrapper {
      display: inline-block;
      height: map-get($fontSizes, 22);
      @include line-clamp(1);
      text-overflow: ellipsis;
      width: 100%;

      &-line {
        display: flex;
        justify-content: space-between;
      }
    }

    &-cta {
      display: flex;
      gap: 8px;
      fill: black;

      a {
        color: black;
      }
    }
  }
}