.gt-aboutDialog {
    margin-top: map-get($sizes, 3);

    &__logo {
        height: 180px;
        width: 400px;
        background-repeat: no-repeat;
        background-color: $midGrey40;
        background-position: center center;
        border-radius: 10px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: map-get($sizes, 3);
        margin: 35px 0 5px;
        &-label {
            font-weight: 600;
            font-size: map-get($fontSizes, 18);
        }
        &-value {
            font-size: map-get($fontSizes, 14);
        }
    }
}