.icon {
  display: inline-flex;

  &--warning {
    color: $colorDanger;
  }

  &--bordered {
    padding: map-get($sizes, 3);
    border: 1px solid #e5e3e0;
    border-radius: 10px;
  }
}
