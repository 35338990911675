.gt-priceAndTimeCalculationPage {
  $blockName: &;
  &__summarySection {
    .gt-accordion__header {
      background-color: $darkPurple;
      color: $whiteColor;
      padding: map-get($sizes, 3) map-get($sizes, 4);
    }
    .gt-accordion__content {
      padding: 0;
    }
    .gt-tasksPanel {
      &__tableHeaderCell {
        &.MuiDataGrid-cell {
          background: $lightGreen;
          color: $darkPurple;
        }
      }
      .MuiDataGrid-root {
        border-radius: 0;
      }
      .MuiDataGrid-columnHeaders {
        background: $lightGreen;
        color: $darkPurple;
        border-radius: 0;
      }
      .MuiDataGrid-cell {
        color: $darkPurple;
        padding: map-get($sizes, 3) map-get($sizes, 4);
      }
    }
  }
  &__regularServices {
    background: $lightGrey60;
    margin: 0 -#{map-get($sizes, 6)};
    padding: map-get($sizes, 8) map-get($sizes, 6) map-get($sizes, 6);

    .gt-accordion__header {
      background-color: $tealColor;
      color: $whiteColor;
    }
    .gt-accordion__content {
      padding: 0;
    }
    .MuiDataGrid-columnHeaders {
      border-radius: 0;
    }
    .gt-serviceStaffingAllocationSummaryPanel {
      .MuiDataGrid-columnHeader {
        background: $lightGreen;
        color: $darkPurple;
      }
      .gt-noBorderCell {
        border: none;
      }
    }
    .gt-tasksPanel {
      .MuiDataGrid-columnHeader {
        background: $lightGreen;
        color: $darkPurple;
      }
    }
    .gt-additionalCostsPanel {
      .MuiDataGrid-columnHeader {
        background: $lightGreen;
        color: $darkPurple;
      }
    }
    .MuiDataGrid-root {
      border-radius: 0;
    }
    .gt-servicePanel {
      margin-bottom: 0;
    }
    .gt-accordion--liteVariant {
      .gt-accordion__header {
        background: $lightGreen;
        color: $darkPurple;
      }
    }
  }
  &--withSelectedServicesState {
    #{$blockName} {
      &__summarySection {
        margin: 0 -#{map-get($sizes, 6)};
        padding: 0 map-get($sizes, 6) map-get($sizes, 6);
        background: $brightPurple10;
      }
      &__topHeader {
        background: $brightPurple10;
        margin: -#{map-get($sizes, 6)};
        padding: map-get($sizes, 6);
      }
    }
  }
  &--withPositiveTopMarginVariant {
    #{$blockName} {
      &__topHeader {
        margin-top: 0px;
        padding-top: map-get($sizes, 3);
      }
    }
  }
  .gt-navigationButtons {
    margin: 0 -#{map-get($sizes, 6)};
    padding: map-get($sizes, 3) map-get($sizes, 9);
  }
}

.gt-priceAndTimeAccordion {
  background-color: transparent;
  box-shadow: none;
  border: 0;

  &__summary {
    background-color: $midGrey80;
    padding: map-get($sizes, 3) map-get($sizes, 6);
    margin: 0 -#{map-get($sizes, 6)};
    color: $darkPurple;
    font-weight: 700;
    font-size: map-get($fontSizes, 16);
    line-height: map-get($fontSizes, 24);

    &-expandedText {
      display: inline-block;
      margin-right: map-get($sizes, 4);
    }

    > div.MuiAccordionSummary-content {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
  }
  &__content {
    &.MuiAccordionDetails-root {
      padding: 0;
    }
  }

  > div.MuiAccordionSummary-root {
    min-height: unset;
  }
}
