.gt-importWarningDialog {
    margin-top: map-get($sizes, 3);

    &__label {
        display: flex;
        justify-content: flex-start;
        gap: map-get($sizes, 2);
        font-weight: 600;
        font-size: map-get($fontSizes, 18);
        margin-bottom: map-get($sizes, 2);
        &-icon {
            color: $redColor;
        }
    }
    &__value {
        font-size: map-get($fontSizes, 14);
    }
}