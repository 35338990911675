.gt-legend {
    padding: map-get($sizes, 3);
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, 2);

    &__item  {
        display: flex;
        align-items: center;
        font-size: map-get($fontSizes, 16);
        gap: map-get($sizes, 2);
        &-filledIndicator {
            color: $greenColor;
        }
        
        &-notStandardIndicator {
            color: $yellowColor;
        }
        
        &-defaultIndicator {
            color: $blueColor;
        }
        
        &-revertIndicator {
            color: $blackColor;
        }
    }
}