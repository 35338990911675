.gt-serviceTitleCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  &__title {
    flex: 1;
    white-space: break-spaces;
  }
}

.gt-serviceCommentCell {
  white-space: break-spaces;
  font-size: map-get($fontSizes, 13);
  line-height: map-get($fontSizes, 14);
}

.gt-dataTableCellWrapper {
  display: flex;
  flex: auto;
  align-items: center;
  white-space: normal;
  &__textField {
    .MuiInputBase-input {
      text-align: center;
      padding: map-get($sizes, 2) map-get($sizes, 1);
    }
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  &__revertIcon {
    color: $blackColor;
    font-size: map-get($fontSizes, 18);
    margin-left: map-get($sizes, 1);
    cursor: pointer;

    &--disabled {
      color: $midGrey80;
      cursor: not-allowed;
    }
  }
  &__multiplicator {
    color: $greyColor;
    width: 40px;
    text-align: center;
    font-weight: 200;
    flex: none;
    word-wrap: break-word;
  }
  &__unit {
    padding-left: map-get($sizes, 1);
  }

  &--calculationComment {
    align-self: flex-start;
  }
}

.gt-dataTableCellValue {
  font-size: map-get($fontSizes, 16);
  padding: map-get($sizes, 2);
}

.gt-dataTableCalculationMethodAmountXTimeXPricePerHour, .gt-dataTableCalculationMethodFrequencyXAmountXTime {
  background-color: rgba($tealColor, 0.1);
}

.gt-dataTableCalculationMethodFrequencyXTimeXPricePerHour {
  background-color: $brightPurple40;
}

.gt-dataTableCalculationMethodTimeXPricePerHour {
  background-color: $midGrey20;
}
