@mixin data-table($tableBgColor, $textColor, $columnBorderColor, $cellBorderColor ) {

    /* This is set to override height 0 of grid content*/
    .MuiDataGrid-main>div:nth-child(2) {
        height: auto !important;
    }

    .MuiDataGrid-columnHeaders {
        background-color: $tableBgColor;
        color: $textColor;
        font-size: map-get($fontSizes, 12);
        line-height: map-get($fontSizes, 18);
        font-weight: 500;
    }

    .MuiDataGrid-columnHeader {
        border-right: 1px solid $columnBorderColor;
    }

    .MuiDataGrid-columnHeaderTitleContainerContent {
        overflow: visible;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .MuiDataGrid-columnHeaderTitle {
        @include line-clamp(3);
        white-space: break-spaces;
        overflow-wrap: break-word;
        font-size: map-get($fontSizes, 14);
        line-height: 1.1;
        text-align: left;
        font-weight: 600;
    }

    .MuiDataGrid-columnSeparator {
        display: none;
    }

    .MuiDataGrid-cell {
        display: flex;
        align-items: center;
        border: 1px solid $cellBorderColor;
        color: $dataTableCellTextColor;
        padding: 0 2px;

        &:focus {
            outline: none;
        }

        .MuiOutlinedInput-root.MuiInputBase-multiline {
            line-height: map-get($fontSizes, 14);
            font-size: map-get($fontSizes, 12);
            padding: 2px map-get($sizes, 2);
            height: 48px;
            border-radius: 0;

            .MuiInputBase-input {
                height: 100% !important;
                overflow: auto !important;
                ;
            }
        }

        .MuiOutlinedInput-root:not(.MuiInputBase-multiline) {
            width: 100%;
            height: 30px;
            background-color: $whiteColor;
        }

        .MuiFormControl-root {
            width: 100%;
        }
    }

    .MuiDataGrid-row {

        &.Mui-selected,
        &:hover {
            background-color: transparent;
        }

        &.Mui-selected:hover {
            background-color: transparent;
        }
    }

    .MuiDataGrid-footerContainer {
        display: none;
    }

    .MuiDataGrid-rowReorderCell .MuiSvgIcon-root{
        font-size: map-get($fontSizes, 18);
    }
    

    /** Section for auto height grid row */
    .MuiDataGrid-renderingZone {
        max-height: none !important;
    }

    .MuiDataGrid-cell {
        line-height: unset !important;
        max-height: none !important;
        white-space: normal;
    }

    .MuiDataGrid-cellContent {
        white-space: pre-line;
        line-height: map-get($fontSizes, 17);
        overflow-wrap: break-word;
    }

    .MuiDataGrid-row {
        max-height: none !important;
    }

    .MuiDataGrid-virtualScroller {
        height: auto !important;
    }

    /* END */

    .MuiDataGrid-virtualScrollerContent {
        height: auto !important;
    }

    .MuiDataGrid-virtualScrollerRenderZone {
        position: relative;
    }
}