
.gt-questionComments {
    position: relative;
    height: 100%;

    &__icon {
      position: absolute;
      top: -30px;
      right: 12px;
      color: $blackColor;
      cursor: pointer;
    }
  
    &__text {
        display: flex;
        flex-direction: column;        
        overflow: auto;
        height: 100%;
        gap: map-get($sizes, 3);
        background-color: $lightYellowColor;
        padding: map-get($sizes, 4) map-get($sizes, 3);
        width: 240px;
        margin: 0 map-get($sizes, 3) map-get($sizes, 2);
        word-wrap: break-word;     
        font-size: map-get($fontSizes, 14);
        line-height: map-get($fontSizes, 20);
        font-weight: 400;

        &-title {            
            font-size: map-get($fontSizes, 14);
            line-height: map-get($fontSizes, 18);
            font-weight: 600;
        }

        &-noComment {
            font-style: italic;
        }
    }
  }