.gt-servicesPage {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  &__servicesContainer {
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, 2);
    padding-right: map-get($sizes, 3);
  }
}

.gt-unselectModalContent {
  display: flex;
  flex-direction: column;
  gap: map-get($sizes, 1);
  &__title {
    font-size: map-get($fontSizes, 18);
    line-height: map-get($sizes, 6);
    font-weight: 600;
  }
  &__content {
    font-size: map-get($fontSizes, 14);
    line-height: map-get($sizes, 5);
    font-weight: 400;
  }
}
