.gt-finalSectionDialog__totalcost-input {
  padding: map-get($sizes, 1) map-get($sizes, 2);
  border-radius: map-get($sizes, 1);
  margin-left: map-get($sizes, 1);
  width: 20%;
  padding: 0px;
  -moz-appearance: textfield;
}

.gt-finalSectionDialog__totalcost-input input {
  height: 22px;
  padding: 0 6px;
  font-size: 14px;
  font: inherit;
}

.gt-finalSectionDialog__reset-total-icon {
  position: absolute;
}

.gt-finalSectionDialog__sufix-text {
  margin-left: 20px;
}