@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.gt-appRoot {
  $blockName: &;
  display: flex;
  flex-direction: column;

  @include gtScroll();

  &__loadingContainer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.56);
    z-index: $loadingOverlayZIndex;

    &-spinner {
      width: 160px;
      height: 160px;
      animation: rotation 1.2s infinite linear;
    }
  }

  &__main {
    display: flex;
    min-width: 1024px;

    &-container {
      flex: 1;
      padding: map-get($sizes, 6) map-get($sizes, 6) 0 map-get($sizes, 6);
      height: calc(100vh - $headerHeight);
      overflow: auto;
      position: relative;
    }
  }

  &--loadingState {
    position: relative;
    overflow: hidden;
    #{$blockName} {
      &__loadingContainer {
        display: flex;
        z-index: $loadingContainerZIndex;
      }
    }
  }

  &--loadingBlurState {
    #{$blockName} {
      &__loadingContainer {
        background: $whiteColor;
      }
    }
  }

  &--withPurpleBackground {
    #{$blockName} {
      &__main {
        &-container {
          background-color: $brightPurple10;
        }
      }
    }
  }
}

.gt-pageTitle {
  font-size: map-get($fontSizes, 18);
  line-height: map-get($fontSizes, 28);
  font-weight: 600;
}

.gt-pageTopPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: map-get($sizes, 3);

  &__title {
    display: flex;
    gap: map-get($sizes, 2);
    align-items: center;
    font-size: map-get($fontSizes, 18);
    line-height: map-get($fontSizes, 28);
    font-weight: 600;
  }

  &__actions {
    button {
      height: 28px;
      font-weight: 500;
      text-transform: capitalize;
      font-size: map-get($fontSizes, 14);
      background-color: $midGrey20;
      margin-left: map-get($sizes, 2);
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
