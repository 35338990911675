.gt-saveFormContent {
  display: flex;
  flex-direction: column;
  gap: map-get($sizes, 4);

  &__row {
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, 1);

    .gt-MultilineField > .MuiInputBase-root {
      height: auto;
    }

    &-titleContainer {
      &-title {
        font-size: map-get($fontSizes, 14);
        line-height: map-get($fontSizes, 20);
      }
    }
  }
}
