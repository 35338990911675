@mixin last-panel-column {
  flex: 0 0 400px;
  padding-left: map-get($sizes, 6);
  padding-right: map-get($sizes, 6);
}
.gt-generateDocumentsPage {
  &__topPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: map-get($sizes, 3);

    &-title {
      font-size: map-get($fontSizes, 18);
      line-height: map-get($fontSizes, 28);
      font-weight: 600;
    }
  }

  &__version {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    border-radius: 4px;
    margin: map-get($sizes, 2) 0;
    padding: map-get($sizes, 3);
    border: 1px solid $greenColor;
    background-color: rgba($greenColor, 0.08);

    &-info {
      display: flex;
      flex-direction: column;

      &-wrapper {
        &-title {
          font-size: map-get($fontSizes, 14);
          line-height: map-get($fontSizes, 20);
          font-weight: 600;
          color: $blackColor;
          margin-right: map-get($sizes, 1);
        }

        &-createdBy {
          font-size: map-get($fontSizes, 14);
          line-height: map-get($fontSizes, 20);
          font-weight: 400;
          color: $greyColor;
        }
      }

      &-comment {
        font-size: map-get($fontSizes, 14);
        line-height: map-get($fontSizes, 20);
        font-weight: 400;
        color: $darkGreyColor;
        margin-top: map-get($sizes, 1);
      }
    }

    &-ctas {
      display: flex;
      flex-direction: row;
      justify-content: end;
      flex: none;
      gap: 6px;
    }
  }

  &__questions {
    display: flex;
    flex-direction: column;
    padding: map-get($sizes, 4) 0;

    .MuiTypography-root {
      line-height: 22px;
    }
  }

  &__language {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: map-get($sizes, 4) 0;
    padding: map-get($sizes, 4) 0;
    margin-bottom: map-get($sizes, 4);
    .MuiButtonBase-root {
      height: map-get($sizes, 6);
    }

    .Mui-selected {
      background-color: rgba($darkPurple, 0.12);
    }
  }
}
.gt-generateDocumentsPanel {
  $blockName: &;
  display: flex;
  flex-direction: column;
  margin-top: map-get($sizes, 4);

  background-color: $panelBgColor;
  border-radius: 10px;
  padding: map-get($sizes, 5);

  &__columns {
    display: flex;

    &-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: map-get($sizes, 2);
    }
    &-right {
      @include last-panel-column;
    }
  }

  &__notification {
    padding-bottom: map-get($sizes, 4);
  }

  &__actions {
    display: flex;

    &-cta {
      border-right: 1px solid $midGrey80;
      padding: 0 map-get($sizes, 6) map-get($sizes, 4) 0;
      display: flex;
      flex-direction: column;
      gap: map-get($sizes, 2);

      button {
        background-color: white;
        font-weight: bold;
      }
    }

    &-overview {
      border-right: 1px solid $midGrey80;
      padding-left: map-get($sizes, 6);
      padding-right: map-get($sizes, 6);
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: map-get($sizes, 2);

      &-infoLines {
        &-line {
          display: flex;
          justify-content: space-between;
          &-email {
            color: $darkerGray;
          }
          &--verticalAlignMiddle {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          &--personalInfo {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }
      }
    }

    &-generatedDocuments {
      @include last-panel-column;
      display: flex;
      flex-direction: column;
      gap: map-get($sizes, 1);

      &-title {
        display: flex;
        align-items: center;

        button {
          margin-bottom: -#{map-get($sizes, 2)};
        }
      }
    }
  }

  h4 {
    color: $darkPurple;
    margin-top: 0;
  }
  h5 {
    color: $darkPurple;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 20);
  }

  strong {
    color: $darkPurple;
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 20);
  }

  a {
    color: $brightPurple;
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 20);
  }

  label {
    display: flex;
    align-items: flex-start;

    > span.MuiCheckbox-root {
      margin-top: -#{map-get($sizes, 2)};
    }
  }

  span {
    color: $darkPurple;
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 20);
  }

  button.MuiButtonBase-root.MuiIconButton-root {
    margin-top: -#{map-get($sizes, 2)};
  }
}
