@mixin gtScroll {
  ::-webkit-scrollbar {
    width: $scrollbarWidth;
    /* Horizontal scroll width*/
    height: $scrollbarHeight;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
