.gt-hourlyRatesForm {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    gap: map-get($sizes, 1);
    padding: map-get($sizes, 1);
    &-staffLevel {
      flex: 3;
    }

    &-newPrice,
    &-pricePerHour {
      width: 120px;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 5px;

      .gt-TextField {
        width: 60px;
        input {
          text-align: right;
        }
      }
    }
    &--title {
      border-bottom: 2px solid $colorPlatinium;
      margin-bottom: map-get($sizes, 2);
    }
  }
}
