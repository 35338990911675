.gt-questionRow {
  $blockName: &;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $questionRowBgColor;
  border: 1px solid $questionRowBorderColor;

  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: map-get($fontSizes, 14);
    padding: map-get($sizes, 2) map-get($sizes, 4);

    &-text {
      white-space: pre-line;
    }

    &-tooltip {
      color: $greyColor;
      white-space: pre-line;

      svg {
        height: map-get($sizes, 5);
        width: map-get($sizes, 5);
      }
    }
  }

  &__control {
    width: 100%;
    flex: 1 1;
    max-width: calc(33% - 36px);
  }

  &__indicators {
    flex: 0 0 80px;
    padding: map-get($sizes, 3);
    display: flex;
    justify-content: flex-end;

    &-icon {
      display: none;
      flex-direction: row;
      align-items: center;
      margin-right: map-get($sizes, 3);
    }

    &-iconFill {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }
  }

  &__comment {
    flex: 1;
    padding: map-get($sizes, 1) map-get($sizes, 4) map-get($sizes, 1) 0;

    &-input {
      width: 100%;

      &>.MuiInputBase-root {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        height: $textAreaHeight;
        padding: map-get($sizes, 2);
        font-size: map-get($fontSizes, 12);
        background-color: $inputBgColor;
      }
    }
  }

  &__datePicker {
    input {
      height: map-get($sizes, 2);
      background-color: $inputBgColor;
      width: map-get($sizes, 172)
    }
  }

  &__separator {
    margin: 0 map-get($sizes, 3);

  }

  &--defaultAnswerState {
    #{$blockName} {
      &__indicators {
        &-icon {
          display: flex;
          color: $blueColor;
        }
      }
    }
  }

  &--notStandardState {
    #{$blockName} {
      &__indicators {
        &-icon {
          display: flex;
          color: $yellowColor;
        }
      }
    }
  }

  &--filledAnswerState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $filledAnswerStateColor;
        }
      }
    }
  }

  &--unfilledAnswerState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $unfilledAnswerStateColor;
        }
      }
    }
  }

  &--isNotAnsweredState {
    #{$blockName} {
      &__indicators {
        &-iconFill {
          color: $isNotAnsweredStateColor;
        }
      }
    }
  }

  &--isInfoTextVariat {
    background-color: $questionRowInfoTextBgColor;

    #{$blockName} {
      &__title {
        font-weight: 600;
      }
    }
  }

  &--isHeadline {
    background-color: $questionRowHeadlineColor;

    #{$blockName} {
      &__title {
        font-weight: 600;
      }
    }
  }

  &--isLicense {
    align-items: flex-start;
  }


}

.gt-tootipHint {
  a {
    color: $whiteColor;
    cursor: pointer;
  }
}