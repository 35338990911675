.gt-servicePanel {
  position: relative;
  margin: map-get($sizes, 4) 0;

  &__addTaskButton {
    margin: map-get($sizes, 1);
    height: 26px;
    width: fit-content;
  }
}
