.gt-finalSectionDialog {
    margin-top: map-get($sizes, 3);

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: map-get($sizes, 3);

        &-question {
            font-weight: 600;
            font-size: map-get($fontSizes, 18);
        }

        &-explanation {
            display: flex;
            align-items: center;
            font-size: map-get($fontSizes, 14);
            display: flex;
            align-items: center;

            &-validity {
                margin-left: map-get($sizes, 1);

                &-dateSeparator {
                    margin: 0 map-get($sizes, 1);
                }
            }
        }

        &-dateValue {
            display: flex;
            align-items: center;
            margin-left: map-get($sizes, 2);
            margin-top: map-get($sizes, 2);
            margin-bottom: 2px;
        }



        &-datePicker.MuiTextField-root {
            height: 35px;

            .MuiInputBase-formControl {
                font-size: map-get($fontSizes, 14);
                width: 120px;

                input {
                    padding: map-get($sizes, 1);
                    background-color: $whiteColor;
                }
            }
        }

    }


}

.gt-finalSectionDialogIcon {
    .MuiSvgIcon-root {
        font-size: 40px;
        color: $darkPurple80;
    }
}