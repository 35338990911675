.gt-TextField {
  width: 100%;

  input {
    @include input-height();
    padding: 0 map-get($sizes, 3);
    font-size: map-get($fontSizes, 14);
    background-color: $inputBgColor;
  }
}

.gt-FilterTextField {
  width: 100%;

  input {
    height: 36px;
    padding: 0 map-get($sizes, 3);
    font-size: map-get($fontSizes, 14);
    background-color: $inputBgColor;
  }
}

.gt-FormGroup {
  width: 100%;

  input {
    @include input-height;
    padding: 0 map-get($sizes, 3);
    font-size: map-get($fontSizes, 14);
    background-color: $inputBgColor;
  }
}

.gt-inputLabel {
  background-color: $lightGrey20;
  z-index: 1;
}

.gt-currencyFieldWrapper {
  display: flex;
  align-items: center;
  flex: 1;

  input {
    text-align: right;
  }

  &__currencySymbol {
    margin-left: map-get($sizes, 1);
    align-items: center;
  }
}

.gt-MultilineField {
  width: 100%;

  &>.MuiInputBase-root {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: $textAreaHeight;
    padding: map-get($sizes, 2);
    font-size: map-get($fontSizes, 14);
    background-color: $inputBgColor;
  }
}

.gt-NumberField {
  width: 100%;

  input {
    @include input-height();
    padding: 0 map-get($sizes, 3);
    font-size: map-get($fontSizes, 14);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .MuiInputBase-formControl {
    background-color: $inputBgColor;
  }
}

.gt-YesNoField {
  &>.MuiFormGroup-root {
    display: flex;
    flex-direction: row;
  }

  .MuiFormControlLabel-label {
    font-size: map-get($fontSizes, 14);
    line-height: map-get($fontSizes, 18);
  }
}

.gt-Select {
  width: 100%;
  background-color: $inputBgColor;

  &--halfWidth {
    width: 50%;
  }

  .MuiSelect-select {
    @include input-height();
    padding: 0 map-get($sizes, 3);
    line-height: $inputHeight;
    font-size: map-get($fontSizes, 14);
  }
}

.gt-multipleEntriesInputContainer {
  width: 100%;

  input {
    @include input-height();
    padding: 0 map-get($sizes, 3);
    line-height: $inputHeight;
    font-size: map-get($fontSizes, 14);
    background-color: $inputBgColor;
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: map-get($sizes, 2);
    margin-bottom: map-get($sizes, 3);
    margin-top: map-get($sizes, $key: 1);

    &-row {
      display: flex;
      gap: map-get($sizes, $key: 2);
      justify-content: space-between;
      margin: map-get($sizes, $key: 1) map-get($sizes, $key: 0);

      &-checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: map-get($sizes, $key: 1) map-get($sizes, $key: 0);

        .Mui-disabled {
          color: $corePurple80;
          opacity: 0.5;
        }
      }

      &-currency {
        display: flex;
        align-items: center;
        gap: map-get($sizes, $key: 1)
      }
    }

  }

}

.gt-multipleEntriesInputContainerButton {
  cursor: pointer;

  &--delete {
    background-color: $lightGrey40;
    color: $darkPurple80;

    &:hover {
      background-color: $whiteColor;
      color: $darkPurple80;
    }
  }

  &--add {
    background-color: $darkPurple80;
    color: $whiteColor ;

    &:hover {
      background-color: $darkPurple80;
      color: $whiteColor ;
    }
  }

  &--disabled {
    filter: grayscale(100%);
    cursor: not-allowed;

    &:hover {
      filter: grayscale(0%);
    }
  }
}

.gt-multipleSelectContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: map-get($sizes, 3) 0;
}

.gt-form {
  &__fieldSection {
    margin: map-get($sizes, 3) 0;
    display: flex;
    flex-direction: column;

    &-label {
      font-size: map-get($fontSizes, 14);
      line-height: map-get($fontSizes, 20);
      font-weight: 600;
      margin-bottom: map-get($sizes, 1);
    }

    &-error {
      color: $redColor;
    }

    .MuiFormHelperText-root {
      font-size: map-get($fontSizes, 14);
      margin: 0;
      padding: 0 map-get($sizes, 7);
      ;
    }
  }

  &__info {
    font-size: map-get($fontSizes, 16);
    line-height: map-get($fontSizes, 20);
    font-weight: 600;
    color: $blackColor;
    margin-bottom: map-get($sizes, 2);
  }
}

.gt-Autocomplete {
  width: 100%;
  background-color: $whiteColor;
  line-height: $inputHeight;

  .MuiAutocomplete-inputRoot {
    @include input-height();
    padding: 0 map-get($sizes, 3);
    line-height: $inputHeight;
    font-size: map-get($fontSizes, 14);
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $blackColor;
    opacity: 1;
    /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $blackColor;
  }
}